import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { COLORS } from '../../utils'

const List = styled.ul`
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px;
  padding: 0px;
`

const ListItem = styled.li`
  padding: 0px;
  margin: 0px;
`

const StyledLink = styled(Link)`
  font-size: 1em;
  text-decoration: none;
`

const StyledNav = styled.nav`
  max-width: 20rem;
  width: 100%;
  @media (max-width: 440px) {
    font-size: 0.75em;
    max-width: 12rem;
  }
`

const HomeLink = styled(Link)`
  &::after {
    position: relative;
    top: -12px;
    content: '💬';
    font-size: 14px;
  }
`

const HeaderMinimal = ({ siteTitle }) => {
  return (
    <div
      style={{
        background: COLORS.white,
        boxShadow:
          '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        zIndex: 99,
        flexShrink: 0,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <div
        style={{
          margin: '0 auto',
          maxWidth: 960,
          padding: '.25rem 1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: 56,
        }}
      >
        <h1
          style={{
            margin: 0,
            padding: 0,
            fontSize: '24px',
          }}
        >
          <HomeLink
            to="/"
            style={{
              textDecoration: 'none',
              textTransform: 'uppercase',
            }}
          >
            SI
          </HomeLink>
        </h1>

        <StyledNav>
          <List>
            <ListItem>
              <StyledLink to="/about">About Me</StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/projects">Projects</StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/blog">Blog</StyledLink>
            </ListItem>
          </List>
        </StyledNav>
      </div>
    </div>
  )
}
export default HeaderMinimal
