import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Footer, HeaderMinimal } from '.'
import HelmetComponent from './helmet'
import { COLORS } from '../utils'

const MinimalLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query GetSiteMetadata {
        site {
          siteMetadata {
            keywords
            description
            siteUrl
            title
            job
            avatarUrl
            twitterHandle
            socialMedia {
              twitterURL
              linkedinURL
              githubURL
            }
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => (
      <React.Fragment>
        <HelmetComponent
          siteTitle={siteMetadata.title}
          description={siteMetadata.description}
          siteUrl={siteMetadata.siteUrl}
          imageUrl={siteMetadata.avatarUrl}
          twitterHandle={siteMetadata.twitterHandle}
        >
          <title>{siteMetadata.title}</title>
          <meta name="keywords" content={siteMetadata.keywords.join(', ')} />
          <meta name="description" content={siteMetadata.description} />
        </HelmetComponent>
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <HeaderMinimal siteTitle={siteMetadata.title} />
          <main
            style={{
              flexGrow: 1,
              width: '100%',
              maxWidth: 960,
              margin: "0 auto",
              minWidth: 275
            }}
          >
            {children}
          </main>
          <Footer backgroundColor={COLORS.white} />
        </section>
      </React.Fragment>
    )}
  />
)

MinimalLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MinimalLayout
