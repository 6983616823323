import React from 'react'

function Footer({ backgroundColor }) {
  const currentYear = new Date().getFullYear()
  return (
    <footer
      style={{
        flexShrink: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '.5em 1em',
        backgroundColor,
      }}
    >
      <p style={{ fontSize: 11, margin: 0 }}>
        &copy; {`${currentYear} Made by Scott Iwako`}
      </p>
    </footer>
  )
}

export { Footer }
