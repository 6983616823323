import React from 'react'
import Helmet from 'react-helmet'

function HelmetComponent({
  title,
  siteTitle,
  description,
  siteUrl,
  imageUrl,
  children,
  twitterHandle,
}) {
  const image = `${siteUrl}${imageUrl}`
  const _title = Boolean(title) ? title : siteTitle
  return (
    <Helmet>
      <title>{siteTitle + (Boolean(title) ? ` | ${title}` : '')}</title>
      {/* OpenGL MetaData */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={_title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={siteUrl} />
      {/* Twitter Metadata */}
      <meta name="twitter:title" content={_title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitterHandle} />
      {children}
    </Helmet>
  )
}

HelmetComponent.defaultProps = { title: null }

export default HelmetComponent
