export const COLORS = {
  white: '#fff',
  black: '#2f3542',
  grey: '#636e72',
  red: '#ff4757',
  lightGrey: '#f1f2f6',
  lightPurple: '#979eb8',
  darkTurqoise: '#3a6772',
  matteGreen: '#70917e',
  lightMatteGreen: '#d6ddd9',
  lightBlue: '#009FD4',
  brightPink: '#f62459',
  lightRoastBrown: '#ad968e',
}

export const BREAK_POINTS = {
  small: 576,
  medium: 768,
  large: 992,
  xl: 1200,
}
