import styled from 'styled-components'

const BlogPostGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 100px auto;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 5rem;
  background: rgb(151, 151, 157);
  background: linear-gradient(
    180deg,
    rgba(151, 151, 157, 1) 0%,
    rgba(0, 159, 212, 1) 100%
  );
`

const BlogPostHeroWrapper = styled.div`
  min-height: 400px;
  box-shadow: 4px 4px 8px #d8d6e0, -4px -4px 8px #f4f2fc;
  border-radius: 40px;
  margin-top: 6rem;
  position: relative;

  @media (max-width: 600px){
    margin-top: 4rem;
  }
`

const HeroImage = styled.div`
  background-image: ${props => props.image && `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 40px;
`

export { BlogPostGrid, BlogPostHeroWrapper, HeroImage }
