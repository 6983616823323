import styled, { css } from 'styled-components'

const Card = styled.div`
  border-radius: 10px;
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  margin-top: 1em;
`

const CardImage = styled.div`
  background: url(${props => props.src});
  height: 200px;
  background-size: cover;
  z-index: 0;
`

export { Card, CardImage }
