import React from 'react'
import { SocialMedia } from '.'
import styled from 'styled-components'

const AvatarContainer = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  @media (max-width: 800px) {
    align-items: center;
  }
`

function AboutAuthorCard({ avatarUrl, socialMedia, name }) {
  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <div>
        <img
          style={{
            width: 150,
            minWidth: 150,
            margin: 0,
            borderRadius: '50%',
            boxShadow: `0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)`,
          }}
          src={avatarUrl}
          alt="a not so professional head shot."
        />
      </div>
      <AvatarContainer>
        <h3 style={{ fontSize: '1.5em' }}>{name}</h3>
        <p>
          <span role="img" aria-label="waving emoji.">👋</span> Hello, thanks for the read!  If you found my work helpful, have constructive feedback, or just want to say hello, connect with me on social media. Thanks in advance!
        </p>
        <SocialMedia
          socialMedia={socialMedia}
          containerStyle={{
            width: '100%',
            alignSelf: 'flex-end',
          }}
        />
      </AvatarContainer>
    </section>
  )
}

export { AboutAuthorCard }
