import React from 'react'
import {
  FaTwitterSquare,
  FaLinkedin,
  FaGithubSquare,
  FaDev,
} from 'react-icons/fa'
import { COLORS } from '../utils/theme'

function SocialMediaIcon({ url, children }) {
  return (
    <a href={url} rel="noopener noreferrer" target="_BLANK">
      {children}
    </a>
  )
}

export function SocialMedia({ socialMedia, containerStyle }) {
  return (
    <div
      style={{
        margin: 0,
        maxWidth: '7rem',
        display: 'flex',
        justifyContent: 'space-around',
        ...containerStyle,
      }}
    >
      <SocialMediaIcon url={socialMedia.twitterURL}>
        <FaTwitterSquare
          style={{ verticalAlign: 'middle' }}
          size={27}
          color={COLORS.brightPink}
        />
      </SocialMediaIcon>

      <SocialMediaIcon url={socialMedia.linkedinURL}>
        <FaLinkedin
          style={{ verticalAlign: 'middle' }}
          size={27}
          color={COLORS.brightPink}
        />
      </SocialMediaIcon>

      <SocialMediaIcon url={socialMedia.githubURL}>
        <FaGithubSquare
          style={{ verticalAlign: 'middle' }}
          size={27}
          color={COLORS.brightPink}
        />
      </SocialMediaIcon>

      <SocialMediaIcon url={socialMedia.devToURL}>
        <FaDev
          size={27}
          color={COLORS.brightPink}
          style={{ verticalAlign: 'middle' }}
        />
      </SocialMediaIcon>
    </div>
  )
}

SocialMedia.defaultProps = {
  containerStyle: {},
}
