import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Footer, Header } from './'
import HelmetComponent from './helmet'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            keywords
            description
            siteUrl
            title
            job
            avatarUrl
            twitterHandle
            socialMedia {
              twitterURL
              linkedinURL
              githubURL
              devToURL
            }
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => (
      <React.Fragment>
        <HelmetComponent
          siteTitle={siteMetadata.title}
          description={siteMetadata.description}
          siteUrl={siteMetadata.siteUrl}
          imageUrl={siteMetadata.avatarUrl}
          twitterHandle={siteMetadata.twitterHandle}
        >
          <title>{siteMetadata.title}</title>
          <meta name="keywords" content={siteMetadata.keywords.join(', ')} />
          <meta name="description" content={siteMetadata.description} />
        </HelmetComponent>
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Header
            siteTitle={siteMetadata.title}
            job={siteMetadata.job}
            socialMedia={siteMetadata.socialMedia}
          />
          <main
            style={{
              padding: 0,
              flexGrow: 1,
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {children}
          </main>
          <Footer />
        </section>
      </React.Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
