import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { COLORS } from '../../utils'
import { SocialMedia } from '..'

const List = styled.ul`
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin: 1em 0px 0px 0px;
  padding: 0px;
`

const ListItem = styled.li`
  padding: 0px;
  margin: 0px;
  font-size: 1.25em;
`

const Name = styled.h1`
  font-size: 2em;
  @media (max-width: 500px) {
    font-size: 1.5em;
  }
`

const JobTitle = styled.h2`
  font-size: 1.5em;
  @media (max-width: 500px) {
    font-size: 1.25em;
  }
`

export const NoUnderlineLink = styled(Link)`
  text-decoration: none;
`

const Header = ({ siteTitle, job, socialMedia }) => (
  <div
    style={{
      background: COLORS.white,
      zIndex: 99,
      flexShrink: 0,
      padding: '0 .25rem',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45rem 1.0875rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <section>
        <Name style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              textDecoration: 'none',
              fontSize: '1.35em',
              textTransform: 'uppercase',
            }}
          >
            {siteTitle}
          </Link>
        </Name>
        <JobTitle style={{ margin: 0 }}>{job}</JobTitle>
        <blockquote style={{ margin: 0, color: COLORS.black }}>
          "I have an insatiable hunger to build for the web."
        </blockquote>
        <SocialMedia socialMedia={socialMedia} />
      </section>

      <nav style={{ width: '100%', maxWidth: '20rem' }}>
        <List>
          <ListItem>
            <NoUnderlineLink to="/about">About Me</NoUnderlineLink>
          </ListItem>
          <ListItem>
            <NoUnderlineLink to="/projects">Projects</NoUnderlineLink>
          </ListItem>
          <ListItem>
            <NoUnderlineLink to="/blog">Blog</NoUnderlineLink>
          </ListItem>
        </List>
      </nav>
    </div>
  </div>
)

export default Header
